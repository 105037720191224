.projectPage {
  width: 100%;
  display: flex;
  flex-direction: column;
  // gap: 4rem;
  padding: 0 2rem;
  background-color: #fff;
  color: #000;

  .projectPage__header {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 8% 0 5%;

    .projectNumber {
      align-self: flex-end;
      font-size: 5vmax;
    }

    .title {
      display: flex;
      flex-direction: column;
      position: relative;

      span {
        font-size: 8vmax;
        // font-family: 'RecklessNeue';
        // font-weight: 150;
        text-transform: uppercase;
        line-height: 1.2;
        letter-spacing: 0.01em;
        // font-feature-settings: 'ss04', 'ss06', 'ss07', 'ss10', 'ss14';
      }

      h3 {
        position: absolute;
        top: 50%;
        right: 0.5rem;
        transform: translate(0, -50%);
        text-transform: uppercase;
        font-size: 0.85rem;
        font-weight: 400;
      }
    }

    .projectDetails {
      text-transform: uppercase;
      font-size: 0.75rem;
      padding-top: 4rem;
      ul {
        display: flex;
        p {
          margin-right: 2rem;
        }
      }
    }
  }

  .projectPage__images {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10rem;

    .fullWidthImage {
      width: 100%;

      img {
        width: 100%;
      }
    }

    .twolSrL {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      img {
        &:first-child {
          width: 30%;
          height: auto;
        }

        &:last-child {
          width: 55%;
          height: auto;
        }
      }
    }

    .oneL {
      width: 100%;

      img {
        width: 60%;
      }
    }

    .oneR {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      img {
        width: 60%;
      }
    }

    .twolLrS {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      img {
        &:first-child {
          width: 55%;
          height: auto;
        }

        &:last-child {
          width: 30%;
          height: auto;
        }
      }
    }
  }

  .projectPage__footer {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;

    h3 {
      font-weight: 400;
    }

    h4 {
      font-size: 0.9rem;
      font-weight: 400;
    }

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 1rem 0 2rem;
      transition: all 300ms ease;

      h1 {
        font-size: 8vmax;
        // font-family: 'RecklessNeue';
        // font-weight: 150;
        text-align: center;
        line-height: 1.2;
        letter-spacing: 0.01em;
        // font-feature-settings: 'ss04', 'ss06', 'ss07', 'ss10', 'ss14';
      }

      .arrow {
        height: 5rem;
        width: 5rem;
        border: 1px solid #000;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 300ms ease;
      }

      &:hover {
        color: #666;

        .arrow {
          background-color: #000;
          color: #fff;
        }
      }
    }
  }
}

// SMALL DEVICES
@media screen and (max-width: 900px) {
  .projectPage {
    padding: 0 1rem;

    .projectPage__header {
      padding: 5rem 0 2rem;

      .title {
        span {
          font-size: 6vmax;
        }

        h3 {
          display: none;
        }
      }

      .projectDetails {
        padding-top: 1rem;
      }
    }

    .projectPage__images {
      gap: 3rem;

      .twolSrL {
        flex-direction: column;
        gap: 3rem;

        img {
          &:first-child {
            width: 60%;
          }

          &:last-child {
            width: 80%;

            align-self: flex-end;
          }
        }
      }

      .twolLrS {
        flex-direction: column;
        gap: 3rem;

        img {
          &:first-child {
            width: 80%;
            align-self: flex-end;
          }

          &:last-child {
            width: 60%;
          }
        }
      }
    }
    .projectPage__footer {
      height: fit-content;
      padding: 10rem 0;
    }
  }
}
