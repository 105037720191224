.workPage {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 5% 3rem;

  .workPageHeader {
    width: 100%;
    max-width: 1200px;
    // height: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem 0 4rem;

    h1 {
      // font-family: 'RecklessNeue';
      // font-weight: 150;
      font-size: 4rem;
      letter-spacing: 0.01em;
      // font-feature-settings: 'ss04', 'ss06', 'ss07', 'ss10', 'ss14';
    }

    .workPageFilters {
      width: 100%;
      // background-color: red;
      height: fit-content;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1rem 0;

      h3 {
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 1rem;
        cursor: pointer;
        letter-spacing: 0.05em;
      }

      .category {
        width: 18rem;
        // background-color: aquamarine;
        // display: flex;
        justify-content: space-between;
        cursor: pointer;
        margin-bottom: 0.25rem;
        display: none;
        // font-family: 'RecklessNeue';
        // font-weight: 150;
        letter-spacing: 0.01em;
        // font-feature-settings: 'ss04', 'ss06', 'ss07', 'ss10', 'ss14';
        font-size: 1.15rem;

        p {
          transition: all 300ms ease;
          &:first-child {
            &:hover {
              color: blue;
            }
          }
        }
      }
    }
  }

  .workGrid {
    width: 100%;
    max-width: 1200px;
    display: flex;
    gap: 2.5rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    // .gridColumn {
    // gap: 2rem;

    .gridItem {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 30rem;
      margin-bottom: 2rem;
      //  gap: 3rem;

      img {
        width: 100%;
        height: 22rem;
        object-fit: cover;
      }

      .itemTitle {
        // position: absolute;
        // bottom: 1.5rem;
        // left: 1.5rem;
        // text-transform: uppercase;
        // font-weight: 600;

        // font-size: 0.9rem;
        // letter-spacing: 0.5px;
        padding-top: 1.5rem;
        height: 6rem;
        // background-color: antiquewhite;

        p {
          text-transform: uppercase;
          font-weight: 600;

          font-size: 0.9rem;
          letter-spacing: 0.5px;
          line-height: 1.2;

          &:last-child {
            text-transform: none;
            // font-family: 'RecklessNeue';
            // font-weight: 150;
            font-size: 1.5rem;
            margin-top: 1rem;
            letter-spacing: 0.01em;
            // font-feature-settings: 'ss04', 'ss06', 'ss07', 'ss10', 'ss14';
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .workPage {
    .workGrid {
      .gridItem {
        height: fit-content;

        img {
          height: 16rem;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .workPage {
    .workGrid {
      gap: 2rem;
      .gridItem {
        // height: fit-content;

        img {
          height: 12rem;
        }

        .itemTitle {
          height: 4rem;
          padding-top: 1rem;

          p {
            &:last-child {
              font-size: 1.25rem;
              margin-top: 0.5rem;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .workPage {
    .workPageHeader {
      padding: 2rem 0;
      gap: 1rem;

      h1 {
        font-size: 3rem;
      }
    }
    .workGrid {
      grid-template-columns: 1fr;
      gap: 1rem;

      .gridItem {
        .itemTitle {
          height: 2.5rem;

          p {
            font-size: 0.75rem;
            &:last-child {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
}
