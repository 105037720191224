.carousel {
  width: 100%;
  min-height: 30rem;
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 5%;

  .projectImage {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .imageOverlay {
      z-index: 0;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  .projectInfo {
    position: absolute;
    bottom: 8%;
    color: white;
    gap: 1rem;
    display: flex;
    flex-direction: column;

    h1 {
      font-size: 3rem;
      font-weight: 400;
      line-height: 1.2;
    }

    a {
      width: fit-content;
      position: relative;
      font-size: 1.25rem;
      top: 0;
      transition: top 2s cubic-bezier(0.16, 1, 0.3, 1);

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: white;
        left: 0;
        bottom: 0;
        transform: scale(0, 1);
        transform-origin: 0% 100%;
        transition: ease 300ms;
      }

      &:hover::after {
        transform: scale(1, 1);
      }
    }

    .navigation {
      margin-top: 1rem;
      display: flex;
      gap: 2rem;

      button {
        background-color: transparent;
        cursor: pointer;

        svg {
          color: white;
          font-size: 3rem;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .carousel {
    height: 20rem;

    .projectInfo {
      h1 {
        font-size: 2rem;
      }
    }
  }
}
