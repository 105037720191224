.homePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  header {
    width: 100%;
    height: 100vh;
    // background-color: #152330;
    position: relative;

    img {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 100;
    }

    .headerText {
      z-index: 100;
      padding-left: 10%;
      width: 60%;
      color: #676767;
      gap: 3rem;
      display: flex;
      flex-direction: column;

      h1 {
        // font-family: 'RecklessNeue';
        // font-weight: 150;
        // letter-spacing: 0.01em;
        line-height: 1;
        // font-feature-settings: 'ss04', 'ss06', 'ss07', 'ss10', 'ss14';
        font-size: 5rem;
      }

      p {
        font-size: 1.25rem;
      }

      a {
        padding: 0.5rem 1.75rem;
        // background-color: #ffffff;
        width: fit-content;
        border-radius: 2rem;
        border: 1px solid #676767;
        text-transform: uppercase;
        font-weight: 500;
        z-index: 101;
      }
    }

    .parallax {
      display: flex;
      align-items: center;
      // justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
      background: rgb(164, 209, 206);
      background: -moz-linear-gradient(
        180deg,
        rgba(164, 209, 206, 1) 0%,
        rgba(218, 254, 254, 1) 100%
      );
      background: -webkit-linear-gradient(
        180deg,
        rgba(164, 209, 206, 1) 0%,
        rgba(218, 254, 254, 1) 100%
      );
      background: linear-gradient(
        180deg,
        rgba(164, 209, 206, 1) 0%,
        rgba(218, 254, 254, 1) 100%
      );
    }

    .parallaxBg {
      position: absolute;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100%;
      background-color: #fff;
      opacity: 0;
    }
  }

  .homePageIntro {
    display: flex;
    width: 100%;
    max-width: 1200px;
    // margin: 0 auto;
    // align-items: center;
    margin: 5.5rem 3rem;
    min-height: 50vh;
    padding: 0 5%;

    h2 {
      flex: 1;
      padding-right: 2rem;
      // font-family: 'RecklessNeue';
      // font-weight: 150;
      // letter-spacing: 0.01em;
      line-height: 1.2;
      // font-feature-settings: 'ss04', 'ss06', 'ss07', 'ss10', 'ss14';
      font-size: 3rem;
    }

    .introText {
      flex: 1;
      display: flex;
      flex-direction: column;
      // align-items: center;
      justify-content: center;
      font-size: 1.25rem;
      line-height: 1.4;
      gap: 2rem;

      p {
        // margin-bottom: 1rem;
      }

      a {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 0.9rem;
        // margin-top: 2rem;
      }
    }
  }

  .homePageWork {
    display: flex;
    width: 100%;
    min-height: 80vh;
    background-color: #f2efe6;

    .image {
      width: 65%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .textContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 35%;
      padding: 3rem 5%;
      gap: 3rem;

      h3 {
        // font-family: 'RecklessNeue';
        // font-weight: 150;
        font-size: 2rem;
        line-height: 1.4;
      }

      p {
        font-size: 1.25rem;
        line-height: 1.5;
      }
    }
  }

  .homePageServices {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 0;
    gap: 4rem;

    h1 {
      font-weight: 400;
      text-transform: uppercase;
      margin-bottom: 2rem;
    }

    .serviceContainer {
      display: flex;
      justify-content: center;
      width: 100%;

      .image {
        height: 15rem;
        // width: 15rem;
        aspect-ratio: 1/1;
        // flex: 1;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .text {
        // flex: 2;
        display: flex;
        flex-direction: column;
        // padding: 0 5% ;
        width: 50%;
        font-size: 1.15rem;
        line-height: 1.4;
        gap: 2rem;

        h3 {
          // font-family: 'RecklessNeue';
          // font-weight: 150;
          font-size: 1.5rem;
          letter-spacing: 0.01em;
          // font-feature-settings: 'ss04', 'ss06', 'ss07', 'ss10', 'ss14';
        }
      }
    }
  }
  .homePageContact {
    width: 100%;
    height: 25rem;
    // background-color: #f2efe6;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    a {
      // font-family: 'RecklessNeue';
      // font-weight: 150;
      font-size: 2.5rem;
      letter-spacing: 0.01em;
      // font-feature-settings: 'ss04', 'ss06', 'ss07', 'ss10', 'ss14';
    }

    h2 {
      font-weight: 300;
      font-size: 1.75rem;
    }
  }
}

@media screen and (max-width: 1250px) {
  .homePage {
    header {
      .headerText {
        width: 80%;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .homePage {
    header {
      .headerText {
        width: 90%;
        h1 {
          font-size: 4rem;
        }
      }
    }

    .homePageIntro {
      gap: 2rem;
    }

    .homePageWork {
      flex-direction: column;

      .image {
        width: 100%;
      }

      .textContainer {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .homePage {
    header {
      .headerText {
        h1 {
          font-size: 3rem;
        }
      }
    }
    .homePageIntro {
      flex-direction: column;

      h2 {
        font-size: 2rem;
      }

      .introText {
        font-size: 1rem;
      }
    }

    .homePageWork {
      .textContainer {
        p {
          font-size: 1rem;
        }
      }
    }

    .homePageServices{
      padding: 2rem 5% 3rem;
      gap: 2rem;

      h1 {
        margin-bottom: 0;
        font-size: 1.75rem;
      }

      .serviceContainer {
        .image {
          display: none;
        }

        .text {
          width: 100%;
          padding-left: 0 !important;
          gap: 0.75rem;

          h3 {
            font-size: 1.25rem;
            line-height: 1.2;
          }
          p {
            font-size: 1rem;
          }
        }
      }

    }

    .homePageContact {
      height: fit-content;
      padding: 4rem 5%;
      gap: 1rem;

      h2 {
        font-size: 1.5rem;
      }

      a {
        font-size: 2rem;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .homePage {
    header {
      .headerText {
        h1 {
          font-size: 2rem;
        }
      }
    }

    .homePageIntro {
      min-height: unset;
      margin: 4rem 3rem;

      h2 {
        padding-right: 0;
        font-size: 1.75rem;
        flex: none;
      }

      .introText {
        flex: none;
      }
    }

    .homePageWork {
      .textContainer {
        h3{
          font-size: 1.75rem;
          line-height: 1.2;
        }
      }
    }
  }
}
