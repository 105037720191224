.aboutPage {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  padding: 12rem 0 3rem;
  position: relative;

  .aboutText {
    width: 74vw;
    padding-left: 5%;
    padding-bottom: 6rem;
    // width: 45%;

    p {
      font-size: 3rem;
      line-height: 1.4;
      // font-family: 'RecklessNeue';
      // font-weight: 150;
      // letter-spacing: 0.01em;
      // font-feature-settings: 'ss04', 'ss06', 'ss07', 'ss10', 'ss14';
      margin-bottom: 4rem;
    }

    .aboutButton {
      display: flex;
      align-items: center;
      text-transform: uppercase;
      font-size: 0.9rem;
      font-weight: 600;
      cursor: pointer;
      width: fit-content;

      svg {
        font-size: 1rem;
        margin-right: 0.5rem;
        color: orange;
      }
    }
  }

  .aboutUs {
    width: 100%;
    background-color: #fff;
    padding: 0rem 5% 6rem;

    p {
      width: 74vw;
      font-size: 1.25rem;
      line-height: 1.4;
      // font-family: 'RecklessNeue';
      // font-weight: 150;
      // letter-spacing: 0.01em;
      // font-feature-settings: 'ss04', 'ss06', 'ss07', 'ss10', 'ss14';
    }
  }

  .aboutServices {
    // height: 40rem;
    width: 100%;
    background-color: #f2efe6;
    padding: 0rem 5%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;

    .title {
      h3 {
        padding: 6rem 0;
        position: sticky;
        top: 1rem;
        left: 1rem;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1rem;
      }
    }

    .facts {
      display: flex;
      flex-direction: column;
      padding: 6rem 0;
      gap: 4rem;

      .factContainer {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
    }

    .services {
      display: flex;
      flex-direction: column;
      padding: 6rem 0;
      // align-items: center;
      // width: 64vw;
      // margin: 0 auto;
      gap: 4rem;

      .serviceContainer {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        h3 {
          text-transform: uppercase;
          font-weight: 600;
          font-size: 1rem;
          line-height: 1.2;
          // margin-bottom: 1rem;
        }

        h4 {
          font-size: 5rem;
          line-height: 1;
          // font-family: 'RecklessNeue';
          // font-weight: 150;
          // letter-spacing: 0.01em;
          // font-feature-settings: 'ss04', 'ss06', 'ss07', 'ss10', 'ss14';
        }
      }
    }
    p {
      font-size: 2rem;
      line-height: 1.2;
      // font-family: 'RecklessNeue';
      // font-weight: 150;
      // letter-spacing: 0.01em;
      // font-feature-settings: 'ss04', 'ss06', 'ss07', 'ss10', 'ss14';
    }
  }

  .teamHeader {
    padding: 6rem 0;
    // height: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    p {
      font-size: 3.5rem;
      line-height: 1.1;
      // font-family: 'RecklessNeue';
      // font-weight: 150;
      // letter-spacing: 0.01em;
      // font-feature-settings: 'ss04', 'ss06', 'ss07', 'ss10', 'ss14';
      width: 50%;
      margin-bottom: 3rem;
    }

    .joinUs {
      margin: 3rem 0 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-size: 0.9rem;
      font-weight: 600;

      svg {
        font-size: 1rem;
        margin-right: 0.5rem;
        color: orange;
      }
    }

    .joinUsText {
      width: 100%;
      display: flex;
      justify-content: center;

      p {
        font-size: 1.25rem;
        line-height: 1.4;
      }
    }
  }

  .teamGrid {
    width: 100%;
    // max-width: 1200px;
    display: grid;
    gap: 3rem;
    grid-template-columns: repeat(4, 1fr);

    .gridColumn {
      gap: 3rem;

      .gridItem {
        // background-color: aliceblue;
        height: fit-content;
        margin-bottom: 1rem;

        img {
          width: 100%;
          height: 22rem;
          object-fit: cover;
        }

        p {
          font-size: 0.8rem;
          line-height: 1.4;
          padding: 0.5rem 1rem;

          span {
            font-size: 0.9rem;
            font-weight: 600;
            text-transform: uppercase;
          }

          svg {
            font-size: 0.5rem;
            margin: 0 0.25rem;
            color: orange;
          }
        }
      }
    }
  }
}

.aboutUsOverlay {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(174, 174, 174, 0.5);
  inset: 0;
  z-index: 102;
  opacity: 1;
  overflow-y: scroll;

  .aboutUsContainer {
    position: static;
    width: 80vw;
    height: 100%;
    background-color: black;
    color: white;
    top: 0;
    left: 0;
    overflow-y: auto;
    overscroll-behavior: contain;
    padding: 6.25rem 10rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    h3 {
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 1rem;
    }

    p {
      font-size: 2.75rem;
      // font-family: 'RecklessNeue';
      // font-weight: 150;
      // letter-spacing: 0.01em;
      // font-feature-settings: 'ss04', 'ss06', 'ss07', 'ss10', 'ss14';
      line-height: 1.1;
    }
  }
  div {
    &:last-child {
      width: 20vw;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 1200px) {
  .aboutPage {
    .aboutText {
      width: 90%;
    }

    .teamHeader {
      p {
        width: 70%;
      }
    }

    .teamGrid {
      .gridColumn {
        .gridItem {
          img {
            height: 18rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .aboutPage {
    .aboutText {
      p {
        font-size: 2.25rem;
      }
    }

    .teamHeader {
      p {
        font-size: 2.75rem;
        // width: 70%;
      }
    }

    .teamGrid {
      padding: 0 5%;
      grid-template-columns: repeat(2, 1fr);
      gap: 1.5rem;

      .gridColumn {
        margin-top: 0 !important;

        .gridItem {
          aspect-ratio: 4/5;
          // margin-bottom: 1.5rem;

          img {
            height: 100%;
          }

          p {
            font-size: 0.75rem;

            span {
              font-size: 0.85rem;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .aboutPage {
    .aboutText {
      padding-bottom: 3rem;
      p {
        font-size: 2rem;
      }
    }

    .aboutServices {
      grid-template-columns: repeat(2, 1fr);
      .services {
        gap: 2rem;
        padding: 4rem 0;
        .serviceContainer {
          h4 {
            font-size: 3rem;
          }
        }
      }

      p {
        font-size: 1.25rem;
      }
    }

    .teamHeader {
      padding: 4rem 0;
      p {
        font-size: 2rem;
        width: 80%;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .aboutPage {
    .aboutText {
      p {
        font-size: 1.75rem;
      }
    }

    .aboutServices {
      gap: 0;
      .title {
        h3 {
          padding: 4rem 0;
          font-size: 0.9rem;
        }
      }
      .services {
        gap: 1.25rem;
        .serviceContainer {
          gap: 0.5rem;

          h3 {
            font-size: 0.85rem;
          }
        }
      }
      p {
        font-size: 1rem;
      }
    }

    .teamHeader {
      padding: 2rem 0;
      p {
        font-size: 1.5rem;
        width: 90%;
      }
    }

    .teamGrid {
      grid-template-columns: 1fr;
      gap: 0;
      justify-items: center;

      .gridColumn {
        width: 80%;
      }
    }
  }
}
