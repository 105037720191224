.privacyPage {
  width: 100%;
  display: flex;
  flex-direction: column;
  // gap: 2rem;
  padding: 10rem 5% 4rem;

  section {
    width: 80%;
    align-self: flex-end;
  }

  h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
  }

  h2 {
    margin: 1.5rem 0;
  }

  h3 {
    margin: 1rem 0;
  }

  p {
    margin-bottom: 0.5rem;
  }

  ul {
    margin-bottom: 1rem;

    li {
      list-style: disc;
      margin-left: 1rem;
    }
  }

  span {
    font-weight: 600;
  }
}

@media screen and (max-width: 700px) {
.privacyPage {
  section{
    width: 100%;
    align-self: flex-start;
  }
}
}