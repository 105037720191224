.contactPage {
  width: 100%;
  // min-height: 100vh;
  // min-height: 55rem;
  display: flex;
  // flex-direction: column;
  // align-items: center;
  justify-content: center;
  // margin-top: 7rem;
  padding: 5rem 5% 0;

  .contactForm {
    // background-color: #ededed;
    flex: 1;
    // height: fit-content;
    padding: 3rem 2rem;

    h3 {
      margin-bottom: 2rem;
      font-size: 2rem;
      // font-weight: 150;
      // text-transform: uppercase;
      // letter-spacing: 0.5px;
      // letter-spacing: 0.01em;
      // font-feature-settings: 'ss04', 'ss06', 'ss07', 'ss10', 'ss14';
    }

    form {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 1.5rem;
      // padding: 2rem;
      // padding:0 5%;

      .input {
        width: 100%;
        // background-color: red;

        p {
          text-transform: uppercase;
          font-size: 0.9rem;
        }

        input,
        textarea {
          width: 100%;
          font-size: 1rem;
          padding: 0 0.5rem 0.5rem;
          background-color: inherit;
          resize: none;
          border-bottom: 2px solid #6c6c6c;
          font-family: inherit;
        }
      }
      button {
        width: 10rem;
        margin-top: 2rem;
        background-color: orange;
        color: #fff;
        padding: 0.75rem 2.5rem;
        border-radius: 3px;
        text-transform: uppercase;
        font-weight: 400;
        align-self: flex-end;
        cursor: pointer;
      }
    }
  }

  .contactInfo {
    // position: absolute;
    // height: fit-content;
    // width: 20rem;
    // left: 42rem;
    // top: 15rem;
    flex: 1;
    // background-color: #909090;
    color: black;
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;

    h3 {
      text-transform: uppercase;
      font-size: 0.9rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
      display: flex;
      align-items: center;
      padding-left: 10%;

      svg {
        font-size: 1.5rem;
        margin-right: 0.5rem;
        color: orange;
        // display: none;
      }
    }

    a,
    p {
      // font-weight: 150;
      font-size: 1.15rem;
      // font-family: 'RecklessNeue';
      // font-weight: 150;
      // letter-spacing: 0.01em;
      // font-feature-settings: 'ss04', 'ss06', 'ss07', 'ss10', 'ss14';
      line-height: 1.4;
    }

    .contactInfo-row {
      padding-left: 10%;
      margin-bottom: 2rem;
      font-size: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .contactPage {
    flex-direction: column;

    .contactInfo {
      h3 {
        padding-left: 0;
      }
      .contactInfo-row {
        padding-left: 0;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .contactPage {
    .contactForm {
      padding: 3rem 0;

      form {
        button {
          width: 100%;
        }
      }
    }

    .contactInfo {
      padding: 1rem 0 3rem;
    }
  }
}
