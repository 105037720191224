:root {
  --primary: #175291;
}

/* @font-face {
  font-family: 'RecklessNeue';
  font-style: normal;
  font-weight: 150;
  src: url('./fonts/RecklessNeue-Book.ttf') format('truetype');
} */

/* @font-face {
  font-family: 'NeueHaasDisplay';
  font-style: normal;
  font-weight: 100;
  src: url('./fonts/NeueHaasDisplayXXThin.ttf') format('truetype');
} */

/* @font-face {
  font-family: 'NeueHaasDisplay';
  font-style: normal;
  font-weight: 200;
  src: url('./fonts/NeueHaasDisplayXThin.ttf') format('truetype');
} */

/* @font-face {
  font-family: 'NeueHaasDisplay';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/NeueHaasDisplayThin.ttf') format('truetype');
} */

@font-face {
  font-family: 'Futura';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/FuturaLight.ttf') format('truetype');
}

/* @font-face {
  font-family: 'NeueHaasDisplay';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/NeueHaasDisplayLight.ttf') format('truetype');
} */
@font-face {
  font-family: 'Futura';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/FuturaBook.ttf') format('truetype');
}

/* @font-face {
  font-family: 'NeueHaasDisplay';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/NeueHaasDisplayRoman.ttf') format('truetype');
} */
@font-face {
  font-family: 'Futura';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/FuturaMedium.ttf') format('truetype');
}

/* @font-face {
  font-family: 'NeueHaasDisplay';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/NeueHaasDisplayMediu.ttf') format('truetype');
} */

@font-face {
  font-family: 'Futura';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/FuturaMedium.ttf') format('truetype');
}

/* 
@font-face {
  font-family: 'NeueHaasDisplay';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/NeueHaasDisplayBold.ttf') format('truetype');
} */

/* @font-face {
  font-family: 'NeueHaasDisplay';
  font-style: normal;
  font-weight: 900;
  src: url('./fonts/NeueHaasDisplayBlack.ttf') format('truetype');
} */

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Futura';
  line-height: 1.7;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}
