nav {
  position: fixed;
  width: 100%;
  height: 4rem;
  display: flex;
  padding: 0 5%;
  z-index: 100;

  .navbar {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .logo {
      position: relative;
      height: 2.5rem;
      width: auto;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 100%;
        width: auto;
      }
    }
  }

  .menuButton {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.35rem;
    cursor: pointer;

    .line {
      height: 2px;
      width: 1.5rem;
      background-color: var(--primary);
    }
  }

  .sideMenu {
    position: fixed;
    display: flex;
    flex-direction: column;
    color: white;
    background-color: var(--primary);
    height: 100vh;
    width: 20rem;
    top: 0;
    left: -20rem;
    transition: left 1s cubic-bezier(0.16, 1, 0.3, 1);
    z-index: 101;

    .sideMenu-top {
      width: 100%;
      height: 4rem;
      display: flex;
      justify-content: flex-end;
      padding: 0 2rem;
      align-items: center;
    }

    .sideMenu-links {
      height: calc(100vh - 4rem);
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .sideMenu-navLinks {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .navLink {
          margin: 0.5rem 0;
          width: 100%;
          position: relative;

          a {
            width: 100%;
            position: relative;
            font-size: 2rem;
            font-weight: 400;
            top: 0;
            transition: top 2s cubic-bezier(0.16, 1, 0.3, 1);

            &::after {
              content: '';
              position: absolute;
              width: 100%;
              height: 1px;
              background-color: white;
              left: 0;
              bottom: 0;
              transform: scale(0, 1);
              transform-origin: 0% 100%;
              transition: ease 300ms;
            }

            &:hover::after {
              transform: scale(1, 1);
            }
          }

          .navLink-wrapper::after {
            content: '';
            position: absolute;
            top: 100px;
            left: -10%;
            width: 120%;
            height: 100%;
            background-color: var(--primary);
            margin: 0 auto;
          }
        }
      }

      .sideMenu-socials {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 4rem;
        padding: 2rem;
        z-index: 1;

        p {
          font-size: 1.5rem;
          font-weight: 200;
          margin-left: 1rem;
        }
      }
    }
  }
}
