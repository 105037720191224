footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6rem;
  padding: 6rem 5% 0.5rem;

  section {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    // padding: 0 2rem;
    gap: 2rem;

    img {
      width: auto;
      height: 3rem;
    }

    h2 {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .arrow {
      background-color: transparent;
      padding: 0.5rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        font-size: 2.5rem;
      }
    }

    ul {
      font-weight: 400;

      h3 {
        color: #666;
        font-weight: 400;
        margin-bottom: 1.5rem;
      }
      li {
        margin-bottom: 0.25rem;
      }
    }

    p {
      font-size: 0.85rem;
      color: #666;
    }
  }
}

@media screen and (max-width: 700px) {
  footer {
    padding: 2rem 5% 0.5rem;
    gap: 1.5rem;

    .logo {
      width: fit-content;
      margin-bottom: 1rem;

      img {
        height: 4rem;
      }
    }

    section {
      flex-direction: column;

      ul {
        h3 {
          margin-bottom: 0.5rem;
        }
      }

      &:last-child {
        gap: 0.5rem;
      }
    }
  }
}
